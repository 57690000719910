import { Component, inject } from '@angular/core';
import { NgCamerasComponent } from '../../ng-cameras/ng-cmareas-component';
import { getVehicleFromRouterId } from '@fc-store/selectors';
import { Store } from '@ngrx/store';
@Component({
  selector: 'fc-ng-camera-tab',
  template: `
    <div class="flex flex-col gap-4 p-24">
      <fc-ng-cameras [currentVehicle]="currentVehicle()"></fc-ng-cameras>
    </div>
  `,
  imports: [NgCamerasComponent],
})
export class NgCameraTabComponent {
  store = inject(Store);

  currentVehicle = this.store.selectSignal(getVehicleFromRouterId, {
    equal: (a, b) => a?.id === b?.id,
  });
}
